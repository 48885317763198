import React from "react"
import "./pagination.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAnglesLeft, faAnglesRight } from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"

function Pagination({ pageCount, currentPage }) {
  const prevDisable = currentPage <= 1 ? "disabled" : ""
  const nextDisable = currentPage >= pageCount ? "disabled" : ""
  const nextPage = (currentPage + 1).toString()
  const prevPage = currentPage - 1 === 1 ? "" : (currentPage - 1).toString()

  const Numrange = () => {
    return (
      <>
        {Array.from({ length: pageCount }, (_, index) => (
          <div
            className={`page-item ${
              index + 1 === currentPage ? "actived" : ""
            }`}
          >
            <Link
              className="page-link"
              to={`/news/${index === 0 ? "" : index + 1}`}
            >
              {index + 1}
            </Link>
          </div>
        ))}
      </>
    )
  }
  return (
    <section className="blog-pagination py-5">
      <div className="pagination justify-content-center pagination-container navigation-page">
        <div className={`page-item ${prevDisable}`}>
          <Link className="page-link prev-btn" to={`/news/${prevPage}`} rel="prev">
            <FontAwesomeIcon
              icon={faAnglesLeft}
              style={{ width: "16px", height: "12px" }}
              className="page-icon"
            />
            &nbsp; Prev
          </Link>
        </div>
        <Numrange />
        <div className={`page-item ${nextDisable}`}>
          <Link className="page-link next-btn" to={`/news/${nextPage}`} rel="next">
            Next &nbsp;
            <FontAwesomeIcon
              icon={faAnglesRight}
              style={{ width: "16px", height: "12px" }}
              className="page-icon"
            />
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Pagination
