import React from "react"
import "./blogCard.scss"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
function BlogCard(post) {
  return (
    <section className="blogCard mb-5">
      <GatsbyImage
        image={getImage(post?.featuredImage?.node.localFile)}
        alt="Blog image"
      />
      <div className="blogCard-content">
        <div className="blog-date">
          <div>{post?.date}</div>
        </div>
        <div className="blog-container d-flex flex-column justify-content-center align-items-center">
          <h3 className="sec-title blog-title">{post?.title}</h3>
          <div className="post-date">
            <div>{post?.date}</div>
          </div>
          <div
            className="sec-text blog-excerpt"
            dangerouslySetInnerHTML={{ __html: post?.excerpt }}
          ></div>
          <div className="button black">
            <Link to={`/news/${post?.slug}`}>Read More</Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlogCard
