import React from "react"
import BlogCard from "../../components/BlogCard/blogCard"
import Pagination from "../../components/Pagination/pagination"
import PageHeader from "../../components/PageTitle/pageTitle"
import Layout from "../../components/Layouts/layout"
import { graphql } from "gatsby"
import Seo from 'gatsby-plugin-wpgraphql-seo'

import "./index.scss"
function news({ data, pageContext }) {
  let postLength = data.allWpPost.nodes.length
  let box = {
    title: "PRESS INQUIRY",
    link: { url: "press@hixonmortgage.com", title: "press@hixonmortgage.com" },
  }

  return (
    <Layout>
      <Seo
        title="News"
        postSchema={JSON.parse(
          data.wp.seo.contentTypes.post.schema.raw
        )}
      />
      <section className="blog-post-section">
        <PageHeader title="NEWS" box={box} />
        <h2 className="blog-main-title">Press | Blog</h2>
        <h2 className="blog-main-title mobile">Press/Blog</h2>
        <div className="blog-card-wrapper">
          {data.allWpPost.nodes.map((node, index) => {
            return <BlogCard key={index} {...node} />
          })}
        </div>

        {postLength > 5 ? (
          <Pagination
            pageCount={pageContext.pageCount}
            currentPage={pageContext.currentPage}
          />
        ) : null}
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    wp {
      seo {
        contentTypes {
          post {
            schema {
              raw
            }
          }
        }
      }
    }
    allWpPost(limit: $limit, skip: $skip) {
      nodes {
        title
        excerpt
        slug
        date(formatString: "MM. D.YY")
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1170)
              }
            }
          }
        }
      }
    }
  }
`
export default news
