import React from "react"
import "./pageTitle.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import Button from "../Button/Button"

function PageTitle(props) {
    if (props?.subtitle && !props?.phone && props?.bgLoanOfficer) {
      return (
        <section className="loan-officer-title_bg">
          <div
            className={`pageTitle option-1 ${
              (props?.box?.title && "long-text",
              props?.bgLoanOfficer ? "loan-officer-title_bg" : null)
            }`}
          >
            <div className="sec-title page-title1">
              <h1 className="page-text1">{props?.title}</h1>
            </div>
            <div className="content-container1">
              <div className="sec-title content-text1 text-center">
                {props?.subtitle}
              </div>
            </div>
          </div>
        </section>
      )
    } else if (props?.subtitle && !props?.phone) {
      return (
        <section
          className={`pageTitle option-1 ${
            (props?.box?.title && "long-text",
            props?.bgLoanOfficer ? "loan-officer-title_bg" : null)
          }`}
        >
          <div className="sec-title page-title1">
            <h1 className="page-text1">{props?.title}</h1>
          </div>
          <div className="content-container1">
            <div className="sec-title content-text1 text-center">
              {props?.subtitle}
            </div>
          </div>
        </section>
      )
    } else if (props?.phone && props?.address && !props?.contactSections?.length) {
      return (
        <section className="pageTitle option-3">
          <div className="sec-title page-title3">
            <h1 className="page-text3">{props?.title}</h1>
            <div className="big-text">{props?.subtitle}</div>
          </div>
          <div className="content-container3">
            <a
              className="content-text3 text-center"
              href={props?.phone?.url}
              target="_blank"
            >
              <FontAwesomeIcon
                style={{ color: "#8e4700" }}
                icon={faPhone}
                className="cl-legacy px-2"
              />
              {props?.phone?.title}
            </a>
            <a
              className="content-text3 text-center address"
              href={props?.address?.url}
              target="_blank"
            >
              {props?.address?.title}
            </a>
            <Button
              button={{
                text: props?.button?.title,
                url: props?.button?.url,
                type: "black",
              }}
            />
          </div>
        </section>
      )
    } else if (props?.contactSections?.length) {
      return (
        <section className="pageTitle option-3">
          <div className="sec-title page-title3">
            <h1 className="page-text3">{props?.title}</h1>
          </div>
          <div className="content-container4">
            {props?.contactSections.map(section => {
              return (
                <div className="contact-section">
                  <h3>{section?.title}</h3>
                  <div className="contact-section-row">
                    {section?.phones?.length && (
                      <div className="contact-section-column">
                        {section?.phones.map(phone => {
                          const phoneNumber = phone?.phone.replace(/[^0-9]/g, '')

                          return (
                            <div className="phone">
                              <div className="title">{phone?.title}</div>
                              <a href={`tel:+1${phoneNumber}`}>
                                <FontAwesomeIcon
                                  style={{ color: "#8e4700" }}
                                  icon={faPhone}
                                  className="cl-legacy px-2"
                                />
                                {phone?.phone}
                              </a>
                            </div>
                          )
                        })}
                      </div>
                    )}
                    {section?.addresses?.length && (
                      <div className="contact-section-column">
                        {section?.addresses.map(address => {
                          return (
                            <div className="address">
                              <div className="title">{address?.title}</div>
                              <div dangerouslySetInnerHTML={{__html: address?.address}} />
                            </div>
                          )
                        })}
                      </div>
                    )}
                    {section?.buttons?.length && (
                      <div className="contact-section-column">
                        {section?.buttons.map(button => {
                          return (
                            <div className="button">
                              <Button
                                button={{
                                  text: button?.button?.title,
                                  url: button?.button?.url,
                                  type: "black",
                                  target: button?.button?.target
                                }}
                              />
                            </div>
                          )
                        })}
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </section>
      )
    } else {
      return (
        <section className="pageTitle option-2">
          <div className="sec-title page-title2">
            <h1 className="page-text2">{props?.title}</h1>
          </div>
          <div className="content-container2">
            <p className="mb-0 mr-3">{props?.box?.title}</p>
            <a
              href={`mailto:${props?.box?.link?.url}`}
              className="content2-email"
            >
              {props?.box?.link?.title}
            </a>
          </div>
        </section>
      )
    }
}

export default PageTitle
